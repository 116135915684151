import {ajaxActions} from "../AjaxActions";
import { UserS } from "./UserS";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchFormSubmissionValues = (formSubmissionId) => ajaxActions.get(BASE_URL + "/formSubmissions/" + formSubmissionId + "/values").then(resp => {
    return resp;
});

export const patchFormSubmissionValues = (formSubmissionId, patch) => ajaxActions.patch(BASE_URL + "/formSubmissions/" + formSubmissionId + "/values", patch).then(resp => {
    return resp;
});

export const patchFormSubmission = async (formSubmissionId, patch) => {
    if (patch.submitted) {
        const ipAddress = await UserS.resolveIPAddress();
        if (ipAddress) {
            patch.signerIP = ipAddress;
        }
    }
    const res = await ajaxActions.patch(BASE_URL + "/formSubmissions/" + formSubmissionId, patch);
    return res;
}

export const fetchFormSubmissionOfResource = async (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/formSubmissions").then(resp => {
    return resp;
});

export const deleteFileOfSubmission = (formSubmissionId, fileName) => ajaxActions.del(BASE_URL + "/formSubmissions/" + formSubmissionId + "/files/" + fileName).then(resp => {
    return resp;
});

export const deleteFormSubmissionValue = async (formSubmissionId, name) => {
    const res = await ajaxActions.del(`${BASE_URL}/formSubmissions/${formSubmissionId}/values/${name}`);
    return res.status === 204;
}
