/* https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/ */

export const getClosest = function (elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;

};

export const join = (array, separator) => {
    let validNames = [];
    array.forEach(name => {
        if (name !== null && name !== "" && name !== 0 && name !== " ") {
            validNames.push(name);
        }
    });
    if (validNames.length > 0) {
        return validNames.join(separator + " ");
    } else {
        return "";
    }
};

export const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
        return true;
    }
    return false;
};

export const hasSpecialChar = (str) => {
    return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
};

export const findByTestAttr = (component, attr) => {
    const wrapper = component.find(`[data-testid='${attr}']`);
    return wrapper
};

export const findFirstEmailInString = (searchString) => {
    let emailRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

    let match;
    if (match = emailRegex.exec(searchString)){
        return match[0]
    }
};

export const filterContactInfosFromString = (string) => {
    let contact = {};

    // rm unwanted whitespaces
    string = string.trim();
    string = string.replace(/&nbsp;/g, "")

    // extract email if present
    let optionalEmail = findFirstEmailInString(string);
    if (optionalEmail) {
        contact.email = optionalEmail;
        string = string.replace(optionalEmail, "");
        string = string.trim();
    }

    // rm multiple whitespaces
    string = string.replace(/ +(?= )/g,'');

    if (string !== "") {
        let firstAndLastName = string.split(" ");

        if (firstAndLastName.length === 1) {
            contact.firstName = firstAndLastName[0];
        } else {
            // set last string as lastName
            contact.lastName = firstAndLastName[firstAndLastName.length-1];
            string = string.replace(contact.lastName, "");

            // rm whitespace between last- and firstName
            string = string.trim();

            contact.firstName = string;
        }
    }

    return contact;
};

function setCaretPosition(elemId, caretPos) {
    var el = document.getElementById(elemId);

    el.value = el.value;
    // ^ this is used to not only get "focus", but
    // to make sure we don't have it everything -selected-
    // (it causes an issue in chrome, and having it doesn't hurt any other browser)

    if (el !== null) {

        if (el.createTextRange) {
            var range = el.createTextRange();
            range.move('character', caretPos);
            range.select();
            return true;
        } else {
            // (el.selectionStart === 0 added for Firefox bug)
            if (el.selectionStart || el.selectionStart === 0) {
                el.focus();
                el.setSelectionRange(caretPos, caretPos);
                return true;
            } else { // fail city, fortunately this never happens (as far as I've tested) :)
                el.focus();
                return false;
            }
        }
    }
}

function replaceSelectedText(replacementText, nodeName) {
    let sel, range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            let b = document.createElement(nodeName);
            console.log({replacementText})
            b.innerHTML = replacementText;
            //range.insertNode(b);
        }
    } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.text = replacementText;
    }
}

function getHTMLOfSelection(nodeName) {
    let range;
    if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.deleteContents();
        let b = document.createElement(nodeName);
        b.innerHTML = range.htmlText;
        range.insertNode(b);
        console.log("REPLACED")
        return range.htmlText;
    } else if (window.getSelection) {
        let selection = window.getSelection();
        if (selection.rangeCount > 0) {
            range = selection.getRangeAt(0);

            console.log({selection})

            let clonedSelection = range.cloneContents();
            let div = document.createElement('div');
            div.appendChild(clonedSelection);

            range.deleteContents();

            if (div.innerHTML !== "") {
                let b = document.createElement(nodeName);
                b.innerHTML = div.innerHTML;
                range.insertNode(b);
            } else {
                console.log("empty string")
            }


            return div.innerHTML;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export const isDefined = (str) => str !== undefined && str !== null && str !== '';

export const printify = (str) => isDefined(str) ? str : ""

export const getContactName = (contact) => {
    const { firstName, lastName } = contact;
    return `${printify(firstName)} ${printify(lastName)}`;
}

export const getContactDescription = (contact) => {
    const personalName = getContactName(contact);
    if (isDefined(personalName.trim())) {
        return personalName;
    }
    if (isDefined(contact.companyName)) {
        return contact.companyName;
    }
    return "Kunde";
}