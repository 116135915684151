import React, { useContext, useState } from "react";
import { TransButtonPrimaryWithSpinner } from "../../Atoms/Buttons";
import { ConfirmDialog, useModalState } from "../../Molecules/Dialogs";
import { Icon } from "../../Atoms/Icons";
import Moment from "react-moment";
import { patchFormSubmission } from "../../Service/restapi/formSubmissionService";
import BookingConfirmedConfetti from "../../Atoms/BookingConfirmedConfetti/BookingConfirmedConfetti";
import { Space } from "@mantine/core";

export default ({ formSubmission, reload }) => {
  const { show, toggle } = useModalState(false);
  const [loading, setLoading] = useState(false);
  const [confettiShoot, setConfettiShoot] = useState(false);

  function accepted() {
    setLoading(true);
    patchFormSubmission(formSubmission.id, { submitted: true })
      .then((resp) => {
        if (resp.ok) {
          reload();
          setConfettiShoot(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let styles = {
    container: {
      marginTop: 20,
      marginBottom: 10,
      display: "flex",
    },
    sign: {
      fontFamily: "SafiarSignature, Courier",
      // paddingTop: 0,
      fontSize: 32,
      height: 50,
      maxWidth: 250,
    },
  };

  return (
    <>
      <Space h="xl" />
      <hr />
      <div>
          Mit folgender Bestätigung wird die Zustimmung zu dem obigen Angebot erklärt.
      </div>
      <Space h={50} />
      <div>
        {!formSubmission.lastSubmitted && (
          <center>
            <TransButtonPrimaryWithSpinner
              isLoading={loading}
              style={{ height: 50 }}
              onClickHandler={toggle}
              text={"Angebot annehmen"}
            />
          </center>
        )}
        <BookingConfirmedConfetti active={confettiShoot} />

        {formSubmission.lastSubmitted && (
          <div style={{ marginLeft: 20, alignSelf: "center", height: 50 }}>
            <Icon value={"BOOKED"} style={{ color: "green" }} /> Akzeptiert am{" "}
            <Moment format="L">{formSubmission.lastSubmitted}</Moment>
          </div>
        )}
        <ConfirmDialog
          text={"documents.confirm"}
          subject={"documents.confirm.head"}
          open={show}
          toggle={toggle}
          handler={accepted}
        />
      </div>
    </>
  );
};
