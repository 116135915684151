import { ajaxActions } from "../AjaxActions";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

const login = (shareId: string, password: string) => {
  return ajaxActions.post(`${BASE_URL}/login`, { shareId, password });
};

const logout = (): Promise<boolean> => {
  return ajaxActions.get(`${BASE_URL}/user/logout`).then((resp) => resp.ok);
};

const refreshToken = () => {
  ajaxActions.get(`${BASE_URL}/user/refreshtoken`).catch((err) => {
    console.log("Unable to refresh token");
    throw err;
  });
};

const resolveIPAddress = async (): Promise<string | null> => {
  const res = await fetch("https://api64.ipify.org?format=json");
  if (res.ok) {
    const resJSON = await res.json();
    return resJSON.ip;
  }
  return null;
};

export const UserS = {
  login,
  refreshToken,
  logout,
  resolveIPAddress,
};
